import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-top: 1px solid ${(p) => p.theme.colors.border};

  transform: translateY(100%);

  transition: all 0.3s;

  @media (max-width: 800px) {
    padding: 0 ${(p) => p.theme.spacing.medium};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  padding: ${(p) => `${p.theme.spacing.medium} ${p.theme.spacing.xLarge}`};

  display: flex;
  flex-direction: row;

  svg {
    min-width: ${({ theme }) => theme.font.large};
    min-height: ${({ theme }) => theme.font.large};

    margin-right: ${(p) => p.theme.spacing.small};
  }

  p {
    font-size: ${({ theme }) => theme.font.small};

    margin-right: auto;

    a {
      text-decoration: underline;

      transition: 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  button {
    margin-left: ${(p) => p.theme.spacing.small};
  }

  @media (max-width: 768px) {
    padding: ${(p) => p.theme.spacing.medium};
  }
`;
