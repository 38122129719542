/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect } from 'react';
import { BiCookie } from 'react-icons/bi';

import Link from 'next/link';

import { ICookies } from '@pra-vendas-themes/interfaces/ICookies';

import { Button } from 'components/Button';

import { Container, Content } from './styles';

const Cookies: FC<ICookies> = ({ cookies }) => {
  const defaultCookiesText =
    'Este site usa cookies para garantir que você obtenha a melhor experiência.';

  const acceptCookies = useCallback(() => {
    const cookiesElement = document.getElementById('cookies-container');

    localStorage.setItem('@PraVendas:cookiesAccepted', 'true');

    if (cookiesElement) cookiesElement.style.transform = 'translateY(100%)';
  }, []);

  useEffect(() => {
    const cookiesElement = document.getElementById('cookies-container');

    const storagedCookiesAccepted = localStorage.getItem(
      '@PraVendas:cookiesAccepted'
    );

    if (cookiesElement) {
      if (storagedCookiesAccepted && storagedCookiesAccepted === 'true') {
        cookiesElement.style.transform = 'translateY(100%)';
      } else {
        cookiesElement.style.transform = 'translateY(0)';
      }
    }
  }, []);

  return (
    <Container id="cookies-container">
      <Content>
        <BiCookie />

        <p>
          {cookies?.text || defaultCookiesText}

          {cookies?.link && (
            <Link href={cookies.link} target="_blank">
              Saber mais
            </Link>
          )}
        </p>

        <Button font="small" onClick={acceptCookies}>
          Entendi
        </Button>
      </Content>
    </Container>
  );
};

export default Cookies;
